import React from "react"
import Accueil from "../components/Accueil/Accueil.jsx"
import Seo from "../components/SEO"

export default function Home() {



  return (
    <>
      <Seo title="Accueil" />
      <Accueil />
      <TrustIndexWidget></TrustIndexWidget>
    </>
  )
}

function TrustIndexWidget() {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.trustindex.io/loader.js?d446c9840f04284f44163c8a8bd';
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="trustindex-widget" />;
};